import { useCallback, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

type Handler = (ev: MessageEvent) => void;

// TODO: Probably migrate to using MessageChannel in the future
export const useMessenger = () => {
  const handlersRef = useRef<Handler[]>([]);

  useEffect(() => {
    const listener = (ev: MessageEvent) => {
      handlersRef.current.forEach((handler) => handler(ev));
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);

  const send = useCallback((message: Record<string, unknown>) => {
    window.parent.postMessage(message, "*");
  }, []);

  const sendAndWait = useCallback(
    async (message: Record<string, unknown>) => {
      const key = uuidv4();
      send({ ...message, key, acknowledge: true });
      return new Promise((resolve) => {
        handlersRef.current.push((ev) => {
          if (typeof ev.data === "object" && ev.data.key === key) {
            resolve(ev.data);
          }
        });
      });
    },
    [send]
  );

  return { send, sendAndWait };
};
