import { gql, useQuery } from "@apollo/client";
import { UserForSatismeter } from "generated/schemaTypes";
import Script from "next/script";
import React, { useEffect } from "react";

declare global {
  interface Window {
    satismeter?: (settings: {
      writeKey: string;
      userId: string;
      traits: {
        name: string;
        email: string;
        createdAt: string;
      };
    }) => void;
  }
}

const USER_FOR_SATISMETER = gql`
  query UserForSatismeter {
    currentUserPrisma {
      id
      primaryEmail
      fullName
      createdAt
      auth0UserId
    }
  }
`;

interface SatismeterWrapperProps {
  children?: React.ReactNode;
}

function SatismeterWrapper({ children }: SatismeterWrapperProps) {
  const { data } = useQuery<UserForSatismeter>(USER_FOR_SATISMETER);
  const user = data?.currentUserPrisma;

  useEffect(() => {
    const isExtensionModalsPath = /^\/extension\/modals/.test(
      window.location.pathname
    );
    if (isExtensionModalsPath) {
      console.info("Satismeter disabled for this path");
      return undefined;
    }

    if (!process.env.NEXT_PUBLIC_SATISMETER_WRITE_KEY) {
      console.info("No Satismeter write key found");
      return undefined;
    }

    // Candidates don't currently have an auth0 id + we don't want them to receive survey
    const isCandidate = user && !user.auth0UserId;
    if (!user || !user.primaryEmail || isCandidate) {
      return undefined;
    }

    const setup = () => {
      window.satismeter?.({
        writeKey: process.env.NEXT_PUBLIC_SATISMETER_WRITE_KEY ?? "",
        userId: user.id,
        traits: {
          name: user.fullName,
          email: user.primaryEmail ?? "",
          createdAt: user.createdAt,
        },
      });
    };

    const intervalId = setInterval(() => {
      if (window.satismeter) {
        setup();
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [user]);

  return (
    <>
      {/* Satismeter Snippet */}
      <Script
        id="satismeter-bootstrap-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function() { window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/js";parent.appendChild(script);})();
          `,
        }}
      />
      {children}
    </>
  );
}

export default SatismeterWrapper;
