import React from "react";

const chainAsChildren = (
  children: React.ReactNode,
  component: React.ReactElement
) => React.cloneElement(component, {}, children);

export type MultiProviderProps = React.PropsWithChildren<{
  providers: React.ReactElement[];
}>;

function MultiProvider({ children, providers }: MultiProviderProps) {
  return <>{providers.reduceRight(chainAsChildren, children)}</>;
}

export default MultiProvider;
