import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";

const LocationHistory = React.createContext<string[]>([]);

interface LocationHistoryProviderProps {
  children?: React.ReactNode;
}

export function LocationHistoryProvider({
  children,
}: LocationHistoryProviderProps) {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>([router.asPath]);

  useEffect(() => {
    const trackHistory = (url: string) => {
      setHistory((prevHistory) => [...prevHistory, url]);
    };
    router.events.on("routeChangeComplete", trackHistory);

    return () => router.events.off("routeChangeComplete", trackHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationHistory.Provider value={history}>
      {children}
    </LocationHistory.Provider>
  );
}

const useLocationHistory = () => useContext(LocationHistory);

export default useLocationHistory;
