import { EditGuideInterviewPlanModalProps } from "client/app/extension/guide/[guideId]/__tmp/__components/EditGuideInterviewPlanModal";
import { EditGuideTemplateInterviewPlanModalProps } from "client/app/extension/guide/[guideId]/__tmp/__components/EditGuideTemplateInterviewPlanModal";
import { HiringDecisionConfirmationViewProps } from "client/components/interview-plan/HiringDecisionSelect";
import { StageChangeConfirmationViewProps } from "client/components/interview-plan/InterviewPlanSelect";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import type { GuideSettingsViewProps } from "components/Guide/GuideSettingsModal";
import { PostMessageModalProps } from "components/MessageComposer/PostMessageModal";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMessenger } from "react-hooks/useMessenger";
import { useQueryStringValue } from "utils/next";
import { v4 as uuidv4 } from "uuid";

import type { ExtensionInstallProps } from "./ExtensionInstallModal";

// TODO: Move ModalName and ModalProps to a separate config file inside config folder
export const enum ModalName {
  TEST = "test",
  POST_MESSAGE = "post_message",
  EXTENSION_INSTALL = "extension_install",
  GUIDE_SETTINGS = "guide_settings",
  EDIT_GUIDE_INTERVIEW_PLAN = "EditGuideInterviewPlan",
  EDIT_GUIDE_TEMPLATE_INTERVIEW_PLAN = "EditGuideTemplateInterviewPlan",
  STAGE_CHANGE_CONFIRMATION = "stage_change_confirmation",
  HIRING_DECISION_CONFIRMATION = "hiring_decision_confirmation",
}

// Importing only types and not the actual modals to allow lazy loading
export type ModalProps = {
  [ModalName.TEST]: {
    input: Record<string, never>;
    result: undefined;
  };
  [ModalName.POST_MESSAGE]: {
    input: PostMessageModalProps;
    result: undefined;
  };
  [ModalName.EXTENSION_INSTALL]: {
    input: ExtensionInstallProps;
    result: undefined;
  };
  [ModalName.GUIDE_SETTINGS]: {
    input: GuideSettingsViewProps;
    result: undefined;
  };
  [ModalName.EDIT_GUIDE_INTERVIEW_PLAN]: {
    input: EditGuideInterviewPlanModalProps;
    result: undefined;
  };
  [ModalName.EDIT_GUIDE_TEMPLATE_INTERVIEW_PLAN]: {
    input: EditGuideTemplateInterviewPlanModalProps;
    result: undefined;
  };
  [ModalName.STAGE_CHANGE_CONFIRMATION]: {
    input: StageChangeConfirmationViewProps;
    result: undefined;
  };
  [ModalName.HIRING_DECISION_CONFIRMATION]: {
    input: HiringDecisionConfirmationViewProps;
    result: undefined;
  };
};

type Payloads = {
  [K in keyof ModalProps]: {
    key: string;
    name: K;
    result: ModalProps[K]["result"];
  };
};
type ResultPayload = Payloads[ModalName];
export type OnResultCallback = (payload: ResultPayload) => unknown;

export interface ModalContextValue {
  active?: {
    key: string;
    name: ModalName;
    // TODO: Improve this typing by linking props to name. Not required.
    props: ModalProps[ModalName]["input"];
  };
  setActive: <N extends ModalName>(
    name: N,
    props: ModalProps[N]["input"],
    key?: string
  ) => string;
  isActive: (name: ModalName) => boolean;
  close: (payload?: ResultPayload) => void;
  addOnResultCallback: (callback: OnResultCallback) => void;
  removeOnResultCallback: (callback: OnResultCallback) => void;
}

const ModalContext = React.createContext<ModalContextValue | undefined>(
  undefined
);

interface ModalProviderProps {
  children?: ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const resultCallbacksRef = useRef<OnResultCallback[]>([]);
  const [active, setActive] = useState<ModalContextValue["active"]>();

  const value = useMemo(
    (): ModalContextValue => ({
      active,
      setActive: (name, props, defaultKey) => {
        const key = defaultKey ?? uuidv4();
        setActive({ key, name, props });
        return key;
      },
      isActive: (name) => active?.name === name,
      close: (payload) => {
        if (payload) {
          resultCallbacksRef.current.forEach((callback) => callback(payload));
        }
        if (active && (!payload || payload.key === active.key)) {
          setActive(undefined);
        }
      },
      addOnResultCallback: (callback) =>
        resultCallbacksRef.current.push(callback),
      removeOnResultCallback: (callback) => {
        resultCallbacksRef.current = resultCallbacksRef.current.filter(
          (cb) => cb !== callback
        );
      },
    }),
    [active]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("ModalContext cannot be used without ModalProvider");
  }

  return context;
};

export function ExtensionModalProvider({ children }: ModalProviderProps) {
  const modalManager = useModalContext();
  const messenger = useMessenger();

  useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (typeof ev.data === "object") {
        if (ev.data.command === "modal") {
          modalManager.setActive(ev.data.name, ev.data.props, ev.data.key);
        } else if (ev.data.command === "modal-close") {
          modalManager.close(ev.data.payload);
        }
      }
    };
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [modalManager]);

  const value = useMemo(
    (): ModalContextValue => ({
      ...modalManager,
      setActive: (name, props, defaultKey) => {
        const key = defaultKey ?? uuidv4();
        messenger.send({ command: "modal", key, name, props });
        return key;
      },
      close: (payload) => {
        messenger.send({ command: "modal-close", payload });
      },
    }),
    [messenger, modalManager]
  );

  return (
    <GuideIdProvider guideId={useQueryStringValue("guideId")}>
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    </GuideIdProvider>
  );
}

export default useModalContext;
