import _ from "lodash";
import { NextApiRequest } from "next";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

function getSingleValueFromQueryString(item: string | string[] | undefined) {
  if (!item) return "";

  return Array.isArray(item) ? item[0] : item;
}

const useQueryStringValue = (queryString: string) => {
  const router = useRouter();
  const queryStringValue = router.query[queryString];
  return getSingleValueFromQueryString(queryStringValue);
};

const webhookPayloadParser = (req: NextApiRequest): Promise<string> =>
  new Promise((resolve) => {
    let data = "";
    req.on("data", (chunk) => {
      data += chunk;
    });
    req.on("end", () => {
      resolve(Buffer.from(data).toString());
    });
  });

const useInitialQueryStringValue = (queryString: string) => {
  const router = useRouter();
  const value = useQueryStringValue(queryString);
  const [initialValue, setInitialValue] = useState(value);

  const unsetInitialValue = useCallback(() => {
    setInitialValue("");
  }, []);

  useEffect(() => {
    if (value) {
      router.replace({
        query: _.omit(router.query, [queryString]),
      });
    }
  }, [queryString, value, router]);

  return {
    initialValue,
    unsetInitialValue,
  };
};

const usePushQueryParams = () => {
  const router = useRouter();
  const pushQueryParams = useCallback(
    (params: Record<string, string>) => {
      router.replace({
        query: { ...router.query, ...params },
      });
    },
    [router]
  );
  return pushQueryParams;
};

const useRemoveQueryParams = () => {
  const router = useRouter();
  const pushQueryParams = useCallback(
    (params: string[]) => {
      router.replace({
        query: _.omit(router.query, params),
      });
    },
    [router]
  );
  return pushQueryParams;
};

export {
  getSingleValueFromQueryString,
  useInitialQueryStringValue,
  usePushQueryParams,
  useQueryStringValue,
  useRemoveQueryParams,
  webhookPayloadParser,
};
