import { ZeusStatus } from "@prisma/client";
import { useEffect } from "react";

type DeprecatedParentHrefPayload = {
  type: "parent-href";
  href: string;
};

type DeprecatedPanelOpenStatusPayload = {
  panelOpen: boolean;
};

type VersionPayload = {
  command: "version";
  value: string;
};

type ParentHrefPayload = {
  command: "parent-href";
  value: string;
};

type PanelOpenStatePayload = {
  command: "panel-open-state";
  value: boolean;
};

type TrackEventPayload = {
  command: "track-event";
  value: {
    eventName: string;
    eventProperties: Record<string, unknown>;
  };
};

export type Message = {
  data:
    | DeprecatedParentHrefPayload
    | ParentHrefPayload
    | DeprecatedPanelOpenStatusPayload
    | VersionPayload
    | NavigateToCandidatePayload
    | TrackEventPayload
    | PanelOpenStatePayload;
  origin?: string;
};

type SetPanelOpenStatePayload = {
  command: "set-panel-open-state";
  value: boolean;
};

type DeprecatedShowPersistentButtonPayload = "show-persistent-button";

type RequestVersionPayload = {
  command: "request-version";
};

type RequestPanelOpenStatePayload = {
  command: "request-panel-open-state";
};

type ReloadPayload = {
  command: "reload";
};

type RequestParentHrefPayload = {
  type: "request-parent-href";
};

type FillGuideUrlPayload = {
  type: "fill-guide-url";
  guideUrl: string;
};

type FlagsLoadedPayload = {
  command: "flags-updated";
  value: string;
};

type OrganizationContextUpdatedPayload = {
  command: "organization-context-updated";
  value: {
    organization: {
      zeusStatus: ZeusStatus;
    };
  };
};

type AuthenticationContextUpdatedPayload = {
  command: "authentication-context-updated";
  value: {
    isLoggedIn: boolean;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    imageUrl?: string;
    createdAt?: Date;
  };
};

type SidepanelIframeInitializedPayload = {
  command: "sidepanel-iframe-initialized";
};

type ModalIframeInitializedPayload = {
  command: "modal-iframe-initialized";
};
type DeprecatedReloadPayload = "reload";
type DeprecatedTogglePanelPayload = "toggle-panel";

type DeprecatedShowMessageButtonPayload = { command: "show-message-button" };

type NavigateToCandidatePayload = {
  command: "navigate-to-candidate";
  greenhouseProfileUrl: string;
};

type CalendarEvent = {
  id: string;
  start: Date;
  end: Date;
};

type LatestCandidateAvailabilityPayload = {
  command: "latest-candidate-availability";
  value: {
    id: string;
    updatedAt: Date;
    calendarEvents: CalendarEvent[];
    guidePost: {
      id: string;
      href: string;
    } | null;
  };
};

export type OutboundMessage =
  | SetPanelOpenStatePayload
  | DeprecatedShowPersistentButtonPayload
  | RequestVersionPayload
  | ReloadPayload
  | DeprecatedReloadPayload
  | DeprecatedTogglePanelPayload
  | RequestParentHrefPayload
  | FillGuideUrlPayload
  | FlagsLoadedPayload
  | OrganizationContextUpdatedPayload
  | SidepanelIframeInitializedPayload
  | ModalIframeInitializedPayload
  | FlagsLoadedPayload
  | DeprecatedShowMessageButtonPayload
  | NavigateToCandidatePayload
  | RequestPanelOpenStatePayload
  | AuthenticationContextUpdatedPayload
  | LatestCandidateAvailabilityPayload;

type Listener = (msg: Message) => void;

export default (listener: Listener, fromOrigin?: string): void => {
  useEffect(() => {
    const handle = (msg: Message) => {
      if (
        process.env.NODE_ENV !== "production" ||
        (msg.origin && (!fromOrigin || fromOrigin === msg.origin))
      ) {
        listener(msg);
      }
    };

    window.addEventListener("message", handle);
    return () => {
      window.removeEventListener("message", handle);
    };
  }, [listener, fromOrigin]);
};
