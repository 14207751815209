import { ApolloError, gql, useQuery } from "@apollo/client";
import { useUser } from "@auth0/nextjs-auth0";
import { useFlags } from "@resource/client-ffs";
import { CustomerPlanEnum } from "@resource/common";
import { RoleEnum } from "enums/role-enum";
import FeatureFlags from "generated/FeatureFlags";
import {
  AuthContext as AuthContextQuery,
  AuthContext_currentUserPrisma as AuthUser,
  AuthContext_currentUserPrisma_currentOrganization_customer_plan as CustomerPlan,
} from "generated/schemaTypes";
import jsCookie from "js-cookie";
import { clearPersistedCache } from "lib/apolloClient";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEventCallback } from "react-hooks/useEventCallback";
import {
  IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
  ORGANIZATION_ID_COOKIE_NAME,
} from "shared/constants/auth";
import {
  LOGIN_COMMUNICATION_CHANNEL,
  postMessageAcrossTabs,
  useAcrossTabsListener,
} from "utils/acrossTabsMessaging";
import {
  checkRolesPermissions,
  PermissionEnum,
  StaffRoleEnum,
} from "utils/permissions";

// We use this file on both the client and server, and importing from @prisma/client causes
// the client to crash, whereas importing from the generated schemaTypes also bombs for this
// specific import
enum ZeusStatusEnum {
  LEGACY = "LEGACY",
  ZEUS = "ZEUS",
  ZEUS_TRANSITION = "ZEUS_TRANSITION",
}

// cookie util
// -----------

// these utils try to use CookieStore, and it not present they fall back to "js-cookie" and polling

// extremely barebones and incomplete types for the experimental CookieStore
// https://developer.mozilla.org/en-US/docs/Web/API/CookieStore
declare global {
  interface Window {
    cookieStore: {
      get: (name: string) => Promise<{ value: string }>;
      set: (options: {
        name: string;
        value: string;
        domain?: string;
        sameSite?: "strict" | "lax" | "none";
      }) => Promise<undefined>;
      addEventListener: (event: "change", cb: () => void) => void;
      removeEventListener: (event: "change", cb: () => void) => void;
    };
  }
}

async function getCookieValue(cookieName: string) {
  return window?.cookieStore
    ? (await window.cookieStore.get(cookieName))?.value
    : jsCookie.get(cookieName);
}

async function setCookieValue({
  secure,
  ...opt
}: Parameters<Window["cookieStore"]["set"]>[0] & {
  secure?: boolean;
}) {
  if (window?.cookieStore) window.cookieStore.set(opt);
  else {
    const { name, value, ...restOptions } = opt;
    jsCookie.set(name, value, { ...restOptions, secure });
  }
}

const COOKIE_POLLING_INTERVAL_MS = 1000;

function useWatchCookieValue(
  cookieName: string
): [value: string | undefined, loading: boolean] {
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(true);

  // set initial value
  useEffect(() => {
    async function setInitialValue() {
      const initialValue = await getCookieValue(cookieName);
      if (initialValue) setValue(initialValue);
      setLoading(false);
    }
    setInitialValue();
  }, [cookieName]);

  // update value when it changes
  const callback = useEventCallback(async () => {
    if (loading) return;
    const currentValue = await getCookieValue(cookieName);
    if (currentValue !== value) setValue(currentValue);
  });
  const intervalRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (window?.cookieStore)
      window.cookieStore.addEventListener("change", callback);
    else
      intervalRef.current = setInterval(callback, COOKIE_POLLING_INTERVAL_MS);

    return () => {
      if (window?.cookieStore)
        window.cookieStore.removeEventListener("change", callback);
      else if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [callback]);

  return [value, loading];
}

// auth context
// ------------

type SSOProvider = "microsoft" | "google";

type ATSIntegration = {
  type: "greenhouse" | "lever";
  displayName: string;
};

type AuthContextValue = {
  user?: AuthUser;
  ssoProvider?: SSOProvider;
  atsIntegration?: ATSIntegration;
  highestRole?: RoleEnum;
  plan?: CustomerPlan;
  error?: ApolloError;
  loading: boolean;
  onboardingComplete?: boolean;
  prospectOnboardingComplete?: boolean;
  organizationIdCookie?: string;
  organizationIdCookieLoading: boolean;
  impersonationMembershipIdCookie?: string;
  impersonationMembershipIdCookieLoading: boolean;
  switchToOrg: (organizationId: string, reload?: boolean) => void;
  impersonateUser: (userMembershipId: string, reload?: boolean) => void;
  checkRolePermissions: (
    permissions: PermissionEnum | PermissionEnum[]
  ) => boolean;
  checkStaffRole: (role: StaffRoleEnum) => boolean;
  checkCustomerPlan: (plan: CustomerPlanEnum) => boolean;
  /**
   * Info on zeus status for current org
   * Should only be used when authenticated
   */
  zeusInfo: {
    status: ZeusStatusEnum;
    /** Zeus only */
    isZeus: boolean;
    /** Zeus transition */
    isZeusTransition: boolean;
    /** Legacy */
    isLegacy: boolean;
    /** Features enabled for Zeus and Zeus-Transition users */
    isZeusFeatureEnabled: boolean;
  };
  onLogout(): Promise<void>;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const AUTH_CONTEXT_QUERY = gql`
  query AuthContext {
    currentUserPrisma {
      id
      isStaff
      isSuperuser
      pitchPageOnboardingComplete
      fullName
      firstName
      lastName
      primaryEmail
      imageUrl
      createdAt
      currentOrganization {
        id
        name
        prospectOnboardingComplete
        zeusStatus
        customer {
          id
          trialStart
          trialDuration
          isGreenhouseIntegrated
          atsIntegration {
            id
            atsType
            displayName
          }
          plan {
            id
            name
          }
        }
      }
      currentUserMembership {
        id
        onboardingComplete
        hasLimitedAccess
        personalProfileId
        highestRole {
          id
        }
      }
    }
  }
`;

export function AuthContextProvider({
  children,
  onLogout,
}: {
  children?: ReactNode;
  onLogout(): Promise<void>;
}) {
  const {
    data,
    error,
    loading,
    refetch: refetchUser,
  } = useQuery<AuthContextQuery>(AUTH_CONTEXT_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [organizationIdCookie, organizationIdCookieLoading] =
    useWatchCookieValue(ORGANIZATION_ID_COOKIE_NAME);
  const [
    impersonationMembershipIdCookie,
    impersonationMembershipIdCookieLoading,
  ] = useWatchCookieValue(IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME);

  const [supertokenCookie] = useWatchCookieValue("sFrontToken");

  // reload the page when the org id cookie changes
  const initialOrgIdCookieRef = useRef<string>();
  useEffect(() => {
    if (!organizationIdCookie) return;
    if (initialOrgIdCookieRef.current === organizationIdCookie) return;
    if (initialOrgIdCookieRef.current !== undefined) window.location.reload();
    initialOrgIdCookieRef.current = organizationIdCookie;
  }, [organizationIdCookie]);

  useEffect(() => {
    if (!supertokenCookie) {
      return;
    }

    refetchUser();
  }, [refetchUser, supertokenCookie]);

  const { [FeatureFlags.ENFORCE_PERMISSIONS]: enforcePermissionsFlag } =
    useFlags();

  const { user: auth0User } = useUser();

  const ssoProvider = useMemo(() => {
    if (!auth0User) return undefined;
    return auth0User.sub?.startsWith("waad") ? "microsoft" : "google";
  }, [auth0User]);

  useEffect(() => {
    postMessageAcrossTabs(
      LOGIN_COMMUNICATION_CHANNEL,
      new Date().toISOString()
    );
  }, [auth0User]);

  useAcrossTabsListener({
    channel: LOGIN_COMMUNICATION_CHANNEL,
    listener: () => refetchUser(),
  });

  const switchToOrg = useCallback(
    async (organizationId: string, reload = true) => {
      if (organizationIdCookie === organizationId) return;
      await setCookieValue({
        name: ORGANIZATION_ID_COOKIE_NAME,
        value: organizationId,
        sameSite: "none",
        secure: true,
      });
      // Also clear the impersonation membership any time we change orgs
      await setCookieValue({
        name: IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
        value: "",
        sameSite: "none",
        secure: true,
      });
      await clearPersistedCache();
      if (reload) {
        window.location.reload();
      }
    },
    [organizationIdCookie]
  );

  const impersonateUser = useCallback(
    async (userMembershipId: string, reload = true) => {
      if (impersonationMembershipIdCookie === userMembershipId) return;
      await setCookieValue({
        name: IMPERSONATION_MEMBERSHIP_ID_COOKIE_NAME,
        value: userMembershipId,
        sameSite: "none",
        secure: true,
      });
      await clearPersistedCache();
      if (reload) {
        window.location.reload();
      }
    },
    [impersonationMembershipIdCookie]
  );

  const baseValue = useMemo(
    () => ({
      checkCustomerPlan: () => false,
      checkRolePermissions: () => false,
      checkStaffRole: () => false,
      loading,
      error,
      organizationIdCookie,
      organizationIdCookieLoading,
      impersonationMembershipIdCookie,
      impersonationMembershipIdCookieLoading,
      impersonateUser,
      switchToOrg,
      onLogout,
      zeusInfo: {
        status: ZeusStatusEnum.LEGACY,
        isZeus: false,
        isZeusTransition: false,
        isLegacy: true,
        isZeusFeatureEnabled: false,
      },
    }),
    [
      error,
      loading,
      organizationIdCookie,
      organizationIdCookieLoading,
      switchToOrg,
      impersonationMembershipIdCookie,
      impersonationMembershipIdCookieLoading,
      impersonateUser,
      onLogout,
    ]
  );

  const zeusInfo = useMemo(() => {
    if (!data?.currentUserPrisma?.currentOrganization) {
      return {
        status: ZeusStatusEnum.LEGACY,
        isZeus: false,
        isZeusTransition: false,
        isLegacy: true,
        isZeusFeatureEnabled: false,
      };
    }

    const { zeusStatus } = data.currentUserPrisma.currentOrganization;
    return {
      status: zeusStatus,
      isZeus: zeusStatus === ZeusStatusEnum.ZEUS,
      isZeusTransition: zeusStatus === ZeusStatusEnum.ZEUS_TRANSITION,
      isLegacy: zeusStatus === ZeusStatusEnum.LEGACY,
      isZeusFeatureEnabled: zeusStatus !== ZeusStatusEnum.LEGACY,
    };
  }, [data]);

  const value: AuthContextValue = useMemo(() => {
    if (!data) return baseValue;

    const user = data.currentUserPrisma ?? undefined;
    const userMembership = user?.currentUserMembership;

    const atsIntegration = user?.currentOrganization?.customer?.atsIntegration;
    const prospectOnboardingComplete =
      user?.currentOrganization?.prospectOnboardingComplete;

    // TODO: memoize
    function checkStaffRole(auth: StaffRoleEnum) {
      switch (auth) {
        case StaffRoleEnum.STAFF:
          return !!(user?.isStaff || user?.isSuperuser);
        case StaffRoleEnum.SUPERUSER:
          return !!user?.isSuperuser;
        default:
          return false;
      }
    }

    const plan = user?.currentOrganization?.customer.plan;
    const onboardingComplete = userMembership
      ? userMembership.onboardingComplete
      : checkStaffRole(StaffRoleEnum.STAFF);

    const highestRole = userMembership?.highestRole?.id
      ? (userMembership.highestRole.id as RoleEnum)
      : undefined;

    // TODO: memoize
    function checkRolePermissions(
      permissions: PermissionEnum | PermissionEnum[]
    ) {
      if (!enforcePermissionsFlag) return true;
      if (user?.isStaff || user?.isSuperuser) return true;
      if (!userMembership || !highestRole) return false;

      const perms = [permissions].flat();
      return checkRolesPermissions([highestRole], perms);
    }

    // TODO: memoize
    function checkCustomerPlan(requiredPlan: CustomerPlanEnum) {
      if (user?.isStaff || user?.isSuperuser) return true;
      return plan?.name === requiredPlan;
    }

    return {
      ...baseValue,
      user,
      ssoProvider,
      atsIntegration: atsIntegration
        ? {
            type: atsIntegration?.atsType === "lever" ? "lever" : "greenhouse",
            displayName: atsIntegration.displayName ?? "",
          }
        : undefined,
      plan,
      highestRole,
      checkCustomerPlan,
      checkRolePermissions,
      checkStaffRole,
      onboardingComplete,
      prospectOnboardingComplete,
      zeusInfo,
    };
  }, [data, baseValue, ssoProvider, enforcePermissionsFlag, zeusInfo]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const value = useContext(AuthContext);
  if (!value) throw new Error("Missing auth context provider");
  return value;
}
