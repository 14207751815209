/* eslint-disable no-underscore-dangle */
import { gql, useQuery } from "@apollo/client";
import { useAuthContext } from "auth/context";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import {
  FetchLatestAvailabilityForExtension,
  FetchLatestAvailabilityForExtensionVariables,
} from "generated/schemaTypes";
import { useEffect, useMemo } from "react";

const FETCH_LATEST_AVAILABILITY = gql`
  query FetchLatestAvailabilityForExtension($where: GuideLookupWhereInput!) {
    guideLookup(where: $where) {
      id
      mostRecentSubmittedAvailability {
        id
        updatedAt
        calendarEvents {
          id
          start
          end
        }
        guidePost {
          id
          href
        }
      }
    }
  }
`;

export const useLatestAvailability = () => {
  const { parentHref, sendMessageToParent } = useExtensionController();

  const { user } = useAuthContext();

  const { isSchedulerPage, greenhouseApplicationId } = useMemo(() => {
    if (parentHref) {
      const parsed = new URL(parentHref || "");
      const _greenhouseApplicationId =
        parsed.searchParams.get("application_id") ?? "";
      const _isSchedulerPage =
        parsed.pathname.includes("interviews/scheduler") &&
        _greenhouseApplicationId;

      return {
        isSchedulerPage: _isSchedulerPage,
        greenhouseApplicationId: _greenhouseApplicationId,
      };
    }

    return {};
  }, [parentHref]);

  const { data } = useQuery<
    FetchLatestAvailabilityForExtension,
    FetchLatestAvailabilityForExtensionVariables
  >(FETCH_LATEST_AVAILABILITY, {
    variables: {
      where: {
        greenhouseApplicationId,
      },
    },
    skip: !isSchedulerPage || !user,
  });

  useEffect(() => {
    if (
      data?.guideLookup?.mostRecentSubmittedAvailability?.calendarEvents.length
    ) {
      const availability = data?.guideLookup?.mostRecentSubmittedAvailability;
      sendMessageToParent({
        command: "latest-candidate-availability",
        value: availability,
      });
    }
  }, [data, sendMessageToParent, parentHref]);
};
