import { useFlags } from "@resource/client-ffs";
import FeatureFlags from "generated/FeatureFlags";
import { ReactNode, useEffect } from "react";

import { useFlagsLoading } from "./IdentifyUserWrapper";

export const SHOULD_PERSIST_CACHE_STORAGE_KEY = "GUIDE_should-persist-cache";

/**
 * HOC that initializes the `shouldPersistCache` flag in local storage for the next
 * app launch.
 */
export function ShouldPeristCacheInitializer({
  children,
}: {
  children?: ReactNode;
}) {
  const { [FeatureFlags.PERSIST_APOLLO_CACHE]: shouldPersistCache } =
    useFlags();
  const { loading: flagsLoading } = useFlagsLoading();

  useEffect(() => {
    if (!flagsLoading) {
      window.localStorage.setItem(
        SHOULD_PERSIST_CACHE_STORAGE_KEY,
        `${shouldPersistCache}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagsLoading]);

  return <>{children}</>;
}
