/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

// copied from https://github.com/Volune/use-event-callback/blob/master/src/index.ts
// using an isomorphic useLayoutEffect to avoid SSR issues

type Fn<ARGS extends any[], R> = (...args: ARGS) => R;

export const useEventCallback = <A extends any[], R>(
  fn: Fn<A, R>
): Fn<A, R> => {
  const ref = useRef<Fn<A, R>>(fn);
  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });
  return useMemo(
    () =>
      (...args: A): R => {
        const { current } = ref;
        return current(...args);
      },
    []
  );
};
