/* eslint-disable global-require */
import {
  ApolloClient,
  ApolloLink,
  FieldReadFunction,
  InMemoryCache,
} from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { offsetLimitPagination as defaultOffsetLimitPagination } from "@apollo/client/utilities";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { SHOULD_PERSIST_CACHE_STORAGE_KEY } from "components/ShouldPeristCacheInitializer";
import { sha256 } from "crypto-hash";
import merge from "deepmerge";
import _ from "lodash";
import isEqual from "lodash/isEqual";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import authRedirectApolloLink from "utils/authRedirectApolloLink";
import { captureSentryError } from "utils/errors/sentryError";
import inIframe from "utils/in-iframe";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
const PERSISTED_CACHE_KEY = "GUIDE_apollo-cache-persist";

export const clearPersistedCache = () => {
  const { localStorage } = window;
  // Set the key in localStorage
  localStorage.setItem(PERSISTED_CACHE_KEY, "");
};

function createIsomorphLink() {
  if (typeof window === "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { SchemaLink } = require("@apollo/client/link/schema");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { schema } = require("../schema");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createSchemaContext } = require("../context");
    return new SchemaLink({
      context: createSchemaContext,
      schema,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { HttpLink } = require("@apollo/client");
  const httpLink = new HttpLink({
    uri: "/api/graphql",
    credentials: "same-origin",
  });
  return ApolloLink.from([
    ...(typeof window !== "undefined"
      ? [
          authRedirectApolloLink(async (errorCode?: string) => {
            if (!window.location.pathname.startsWith("/login")) {
              window.location.href = `${window.location.origin}/login${
                errorCode ? `?error_code=${errorCode}` : ""
              }`;
            }
          }),
        ]
      : []),
    createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: false,
    }),
    httpLink,
  ]);
}

type GuideApolloClient = ApolloClient<Record<string, unknown>>;

let apolloClient: GuideApolloClient;

const defaultPaginationRead: FieldReadFunction = (
  existing,
  { args, variables }
) => {
  const { offset = 0, limit = existing?.length } = args ?? variables ?? {};

  return (
    existing &&
    existing.slice(offset, _.isNull(limit) ? existing?.length : offset + limit)
  );
};

async function createApolloClient() {
  let shouldPersistCache = false;
  if (typeof window !== "undefined") {
    const shouldPersistCacheFromCache = window.localStorage.getItem(
      SHOULD_PERSIST_CACHE_STORAGE_KEY
    );
    shouldPersistCache = shouldPersistCacheFromCache === "true";
  }
  const isExtensionFrame = inIframe();

  const cache = new InMemoryCache({
    possibleTypes: {
      InterviewParticipant: ["Candidate", "EventInterviewer"],
      FieldChanges: [
        "StringFieldChange",
        "BooleanFieldChange",
        "StringArrayFieldChange",
      ],
      CollectionFieldChangeData: [
        "CollectionFieldChangedAddedData",
        "CollectionFieldChangedRemovedData",
        "CollectionFieldChangedReorderedData",
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          guide: defaultOffsetLimitPagination(["order_by"]),
          teammates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          personalProfileFindUnique: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "Interviewer",
                id: args?.where.id,
              });
            },
          },
          guidePostById: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "GuidePost",
                id: args?.id,
              });
            },
          },
        },
      },
      Recipient: {
        keyFields: ["id", "email"],
      },
      Organization: {
        fields: {
          guides: {
            ...defaultOffsetLimitPagination(["orderBy", "where"]),
          },
          guidesV2: {
            ...defaultOffsetLimitPagination(["orderBy", "where"]),
            read: defaultPaginationRead,
          },
          emailTemplates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          jobRoleGuideTemplates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          guideTemplatesView: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          contentTemplates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          interviewTemplates: {
            ...defaultOffsetLimitPagination(["where", "orderBy"]),
            read: defaultPaginationRead,
          },
          interviewTemplateById: {
            read(_existing, { args, toReference }) {
              return toReference({
                __typename: "InterviewTemplate",
                id: args?.id,
              });
            },
          },
          inboxMessages: {
            ...defaultOffsetLimitPagination([]),
            read: defaultPaginationRead,
          },
          paginatedPostTemplates: {
            ...defaultOffsetLimitPagination(["orderBy", "where"]),
            read: defaultPaginationRead,
          },
          postTemplates: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      atssync_greenhousejob: {
        keyFields: ["atssync_id"],
      },
      atssync_greenhousejobstages: {
        keyFields: ["atssync_id"],
      },
      atssync_greenhouseuser: {
        keyFields: ["atssync_id"],
      },
      atssync_greenhouseoffice: {
        keyFields: ["atssync_id"],
      },
      atssync_greenhousedepartment: {
        keyFields: ["atssync_id"],
      },
      atssync_greenhouseaccount: {
        keyFields: ["atssync_id"],
      },
    },
  });

  // Experimental - use local storage when available to persist the cache for fast reload
  if (isExtensionFrame && shouldPersistCache) {
    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    try {
      await persistCache({
        key: PERSISTED_CACHE_KEY,
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        // debug: true,
      });
    } catch (err) {
      captureSentryError(err, "persistCache");
      clearPersistedCache();
      // Continue even if cache persisting fails
    }
  }

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    connectToDevTools: process.env.NODE_ENV === "development",
    link: createIsomorphLink(),
    cache,
    ssrForceFetchDelay: 100,
    name: "guide-web",
    /*
     * Normally we would default to the "cache-first" fetch policy, but that
     * doesn't work well with a cache persistor. We want to use whaterver is in
     * local storage, but if it's not there, we want to fetch from the network.
     */
    ...((process.env.NODE_ENV === "development" || isExtensionFrame) &&
    shouldPersistCache
      ? {
          defaultOptions: {
            watchQuery: {
              fetchPolicy: "cache-and-network",
            },
          },
        }
      : {}),
  });
}

export async function initializeApollo({
  state: initialState = null,
}: {
  state: Record<string, unknown> | null;
}) {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? (await createApolloClient());

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: GuideApolloClient,
  pageProps: AppProps["pageProps"]
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: AppProps["pageProps"]) {
  const [client, setClient] = useState<GuideApolloClient>();
  const router = useRouter();

  const state = pageProps[APOLLO_STATE_PROP_NAME];
  useEffect(() => {
    initializeApollo({ state })
      .then(setClient)
      .catch((err) => {
        captureSentryError(err, "initializeApollo");
        console.error(err);
        router.push("/error");
      });
  }, [state, router]);

  return {
    client,
    clearClientStore: async () => {
      await client?.clearStore();
      clearPersistedCache();
    },
  };
}
