import {
  Customer,
  CustomerPlan,
  Organization,
  User,
  UserMembership,
} from "@prisma/client";
import { RoleEnum } from "enums/role-enum";
import {
  AccountStatusEnum,
  CompanyEmployeeSizeEnum,
  CompanyTalentTeamSizeEnum,
  PersonaEnum,
  PlanPricingModelEnum,
  UserForAnalytics_currentUserPrisma as ResolvedUser,
  UserForAnalytics_currentUserPrisma_currentOrganization as ResolvedOrganization,
  ZeusStatusEnum,
} from "generated/schemaTypes";
import { getTrialData } from "utils/trial";

export const buildResolvedOrganizationFromOrganization = (
  organization: Organization,
  customer: Customer,
  plan: CustomerPlan
) => {
  const resolvedOrganization: ResolvedOrganization = {
    __typename: "Organization",
    companyLogoUrl: organization.companyLogoUrl,
    id: organization.id,
    name: organization.name,
    createdAt: organization.createdAt,
    zeusStatus:
      organization.zeusStatus && ZeusStatusEnum[organization.zeusStatus],
    planPricingModel:
      organization.planPricingModel &&
      PlanPricingModelEnum[organization.planPricingModel],
    accountStatus:
      organization.accountStatus &&
      AccountStatusEnum[organization.accountStatus],
    employeeCountEnum:
      organization.employeeCountEnum &&
      CompanyEmployeeSizeEnum[organization.employeeCountEnum],
    talentTeamCountEnum:
      organization.talentTeamCountEnum &&
      CompanyTalentTeamSizeEnum[organization.talentTeamCountEnum],
    contractStartDate: organization.contractStartDate,
    customer: {
      __typename: "Customer",
      id: customer.id,
      trialDuration: customer.trialDuration,
      trialStart: customer.trialStart,
      name: customer.name,
      plan: {
        __typename: "CustomerPlan",
        id: plan.id,
        name: plan.name,
      },
    },
  };
  return resolvedOrganization;
};

export const buildResolvedUserFromUser = ({
  user,
  userMembership,
  organization,
  customer,
  plan,
}: {
  user: User;
  userMembership: UserMembership;
  organization: Organization;
  customer: Customer;
  plan: CustomerPlan;
}) => {
  const resolvedUser: ResolvedUser = {
    __typename: "User",
    id: user.id,
    intercomHash: "",
    primaryEmail: user.email,
    fullName: user.fullName,
    createdAt: user.createdAt,
    currentUserMembership: {
      __typename: "UserMembership",
      id: userMembership.id,
      createdAt: userMembership.createdAt,
      persona: userMembership.persona && PersonaEnum[userMembership.persona],
    },
    currentOrganization: {
      ...buildResolvedOrganizationFromOrganization(
        organization,
        customer,
        plan
      ),
    },
  };
  return resolvedUser;
};

export const buildCommonOrgAttributes = (
  organization: ResolvedOrganization
) => ({
  id: organization.id,
  name: organization.name,
  avatar: organization.companyLogoUrl,
  plan: organization.planPricingModel,
  status: organization.accountStatus,
  employees: organization.employeeCountEnum,
  talentTeam: organization.talentTeamCountEnum,
  contractStartDate: organization.contractStartDate,
  zeusStatus: organization.zeusStatus,
  createdAt: organization.createdAt,
});

export const buildGroupOrgAttributes = (
  organization: ResolvedOrganization
) => ({
  ...buildCommonOrgAttributes(organization),
  companyId: organization.id,
  companyName: organization.customer.name,
});

export const buildOrganizationProperties = (
  organization: ResolvedOrganization
) => ({
  customer: {
    id: organization.customer.id,
    name: organization.name, //  We have to continue sending this as the organization name so our legacy data in Amplitude is accurate
    plan: organization.customer.plan.name,
  },
  organization: buildCommonOrgAttributes(organization),
  trialData: getTrialData({
    trialDuration: organization.customer.trialDuration,
    trialStart: organization.customer.trialStart,
  }),
});

export const buildCommonIdentifyTraits = ({
  user,
  highestRole,
  organization,
}: {
  user: ResolvedUser;
  highestRole: RoleEnum | undefined;
  organization: ResolvedOrganization | null;
}) => ({
  name: user.fullName,
  email: user.primaryEmail,
  createdAt: user.currentUserMembership?.createdAt,
  persona: user.currentUserMembership?.persona, // will be null for candidates
  role: highestRole,
  ...(organization ? buildOrganizationProperties(organization) : {}),
});

export const trackProperties = {
  sourceEmail: { source: "email" },
  isCustomerSuccess: { customerSuccess: true },
};
