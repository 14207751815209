import { LDUser } from "launchdarkly-js-sdk-common";

import { User } from "./User";

const userAsLdUser = (user: User): LDUser => {
  const organizationId = user.currentOrganization?.id;
  const customerId = user.currentOrganization?.customer.id;
  const customerName = user.currentOrganization?.customer.name;
  const isGrandfatheredPlan =
    user.currentOrganization?.customer.grandfatheredAllFeaturesPlanEnabled;
  const zeusStatus = user.currentOrganization?.zeusStatus;
  const customerPlan = user.currentOrganization?.customer.plan.name;
  return {
    firstName: user.firstName || undefined,
    lastName: user.lastName || undefined,
    custom: {
      ...(customerId ? { customerId } : {}),
      ...(customerName ? { customerName } : {}),
      ...(customerPlan ? { customerPlan } : {}),
      ...(organizationId ? { organizationId } : {}),
      ...(isGrandfatheredPlan ? { isGrandfatheredPlan } : {}),
      ...(zeusStatus ? { zeusStatus } : {}),
      roles: user.roles || [],
    },
    email: user.primaryEmail || undefined,
    key: user.id,
  };
};

export default userAsLdUser;
