import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { useLDClient } from "launchdarkly-react-client-sdk";

import convertUserToLDUser from "./convert-user-to-ld-user";
import { User } from "./User";

type IdentifyFunction = (err: Error | null, flags: LDFlagSet | null) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (...args: any[]) => void;

type UseClientReturnType = {
  identifyUser(user: User, hash?: string, callback?: IdentifyFunction): void;
  on(eventName: string, listener: Listener): void;
  off(eventName: string, listener: Listener): void;
};

export default function useClient(): UseClientReturnType {
  const ldClient = useLDClient();

  const identifyUser = (
    user: User | { anonymous: boolean },
    hash?: string,
    callback?: IdentifyFunction
  ): void => {
    if (ldClient) {
      const ldUser = "anonymous" in user ? user : convertUserToLDUser(user);
      ldClient.identify(ldUser, hash, callback);
    }
  };

  return {
    identifyUser,
    on: (eventName, listener): void => ldClient?.on(eventName, listener),
    off: (eventName, listener): void => ldClient?.on(eventName, listener),
  };
}
