import { QueueOptions, WorkerOptions } from "bullmq";

export const enum QueueName {
  DEFAULT = "default",
  UI = "ui",
  MIGRATION = "migration",
  SYNC_GUIDE = "sync_guide",
  CRONS = "crons",
  DB_MIGRATION = "db_migration",
  NOTIFICATIONS = "notifications",
  TESTING = "testing",
  TRANSLATION = "translation",
  ATSSYNC = "atssync",
}

export const enum WorkerName {
  MASTER = "master",
}

interface Config {
  name: QueueName;
  options?: QueueOptions;
  worker: {
    name: WorkerName;
    options?: WorkerOptions;
  };
}

enum JobPriority {
  LOWEST = 10,
  LOW = 7,
  MEDIUM = 5,
  HIGH = 3,
  HIGHEST = 1,
}

const THIRTY_MINUTES_IN_SECONDS = 60 * 30;
const SEVEN_DAYS_IN_SECONDS = 60 * 60 * 24 * 7;

const queues: Config[] = [
  {
    name: QueueName.DEFAULT,
    worker: { name: WorkerName.MASTER, options: { concurrency: 10 } },
  },
  {
    name: QueueName.UI,
    // Setting removeOnComplete to true so that NEW jobs (with same jobId) can be re-added once they are completed.
    // Note that we will not be able to add NEW jobs (with same jobId) if they are failed and left in the queue
    // unless we set removeOnFail to true as well.
    // If they are removed from the queue or retried successfully then they can be added again.
    options: {
      defaultJobOptions: {
        removeOnComplete: true,
        attempts: 2,
        backoff: 10000,
        priority: JobPriority.HIGHEST,
      },
    },
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
  {
    name: QueueName.SYNC_GUIDE,
    options: {
      defaultJobOptions: { attempts: 3, backoff: 5000 },
    },
    worker: {
      name: WorkerName.MASTER,
      options: {
        concurrency:
          Number(process.env.SYNC_WORKER_CONCURRENCY) > 0
            ? Number(process.env.SYNC_WORKER_CONCURRENCY)
            : 5,
      },
    },
  },
  {
    name: QueueName.MIGRATION,
    options: {
      defaultJobOptions: { attempts: 3, backoff: 5000 },
    },
    worker: {
      name: WorkerName.MASTER,
      options: {
        concurrency: 5,
      },
    },
  },
  {
    name: QueueName.DB_MIGRATION,
    worker: {
      name: WorkerName.MASTER,
      options: {
        concurrency:
          Number(process.env.DB_MIGRATION_WORKER_CONCURRENCY) > 0
            ? Number(process.env.DB_MIGRATION_WORKER_CONCURRENCY)
            : 5,
      },
    },
  },
  {
    name: QueueName.CRONS,
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
  {
    name: QueueName.ATSSYNC,
    options: {
      defaultJobOptions: {
        removeOnComplete: {
          age: THIRTY_MINUTES_IN_SECONDS,
        },
        removeOnFail: {
          age: SEVEN_DAYS_IN_SECONDS,
        },
        attempts: 5,
        backoff: {
          type: "exponential",
          delay: 5000,
        },
      },
    },
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
  {
    name: QueueName.NOTIFICATIONS,
    options: {
      defaultJobOptions: {
        attempts: 3,
        backoff: 5000,
        priority: JobPriority.HIGH,
      },
    },
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
  {
    name: QueueName.TRANSLATION,
    options: {
      defaultJobOptions: { attempts: 3, backoff: 5000 },
    },
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
  {
    name: QueueName.TESTING,
    worker: { name: WorkerName.MASTER, options: { concurrency: 5 } },
  },
];

export default queues;
