import { WorkerName } from "./queues";

// TODO: Read and parse variables from process.env
export default {
  HOSTNAME: "localhost",
  PORT: 3001,
  WORKERS: [WorkerName.MASTER],
  QUEUE_PREFIX: "gq",
  EXTENSION_ID: process.env.NEXT_PUBLIC_CHROME_EXTENSION_IDENTIFIER ?? "",
  NULL_UUID: "00000000-0000-0000-0000-000000000000",
} as const;
