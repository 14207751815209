import { differenceInDays, set as dateSet } from "date-fns";

export const getTrialData = ({
  trialDuration,
  trialStart,
}: {
  trialDuration: number | null | undefined;
  trialStart: Date | null | undefined;
}):
  | {
      isTrial: false;
    }
  | {
      isTrial: true;
      daysPassed: number;
      daysLeft: number;
      isDone: boolean;
      trialDuration: number;
      trialStart: Date;
    } => {
  const isTrial = typeof trialDuration === "number" && trialStart;

  if (!isTrial) {
    return {
      isTrial: false,
    };
  }

  const formattedTrialStart = dateSet(new Date(trialStart), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const daysPassed = differenceInDays(new Date(), formattedTrialStart);
  const daysLeft = trialDuration - daysPassed;
  const isDone = daysLeft <= 0;

  return {
    isTrial: true,
    daysPassed,
    daysLeft,
    isDone,
    trialDuration,
    trialStart,
  };
};
