import { gql, useQuery } from "@apollo/client";
import { useClient } from "@resource/client-ffs";
import {
  CurrentUserForFlags,
  GuideDetailsForIdentity,
  GuideDetailsForIdentityVariables,
} from "generated/schemaTypes";
import React, { useContext, useEffect, useState } from "react";
import { captureSentryError } from "utils/errors/sentryError";
import { useQueryStringValue } from "utils/next";

const ORG_FOR_FLAGS_FRAGMENT = gql`
  fragment OrgForFlagsFragment on Organization {
    id
    name
    zeusStatus
    customer {
      id
      name
      grandfatheredAllFeaturesPlanEnabled
      plan {
        id
        name
      }
    }
  }
`;

const IDENTIFY_USER = gql`
  query CurrentUserForFlags {
    currentUserPrisma {
      id
      firstName
      lastName
      primaryEmail
      currentUserMembership {
        id
        roles
      }
      currentOrganization {
        ...OrgForFlagsFragment
      }
    }
  }
  ${ORG_FOR_FLAGS_FRAGMENT}
`;

const GUIDE_DETAILS_FOR_IDENTITY = gql`
  query GuideDetailsForIdentity(
    $customerSlug: String!
    $guideShortId: String!
  ) {
    guideFindUniqueByShortId(
      where: { customerSlug: $customerSlug, guideShortId: $guideShortId }
    ) {
      id
      candidate {
        id
        firstName
        lastName
        email
        organization {
          ...OrgForFlagsFragment
        }
      }
    }
  }
  ${ORG_FOR_FLAGS_FRAGMENT}
`;

interface FlagsLoadingContextValue {
  loading: boolean;
}

const FlagsLoadingContext = React.createContext<
  FlagsLoadingContextValue | undefined
>(undefined);

export const useFlagsLoading = () => {
  const context = useContext(FlagsLoadingContext);
  if (!context) {
    throw new Error(
      "FlagsLoadingContext cannot be used without FlagsLoadingProvider"
    );
  }

  return context;
};

interface IdentifyUserWrapperProps {
  children?: React.ReactNode;
}

function IdentifyUserWrapper({ children }: IdentifyUserWrapperProps) {
  const client = useClient();
  const [flagsLoading, setFlagsLoading] = useState(true);

  const customerSlug = useQueryStringValue("customerSlug");
  const guideShortId = useQueryStringValue("guideShortId");

  // All guide pages have these 2 parameters; this check is more resilient than maintaining a list of paths
  const isGuidePage = customerSlug && guideShortId;

  const {
    data,
    loading: userDataLoading,
    error: userDataError,
  } = useQuery<CurrentUserForFlags>(IDENTIFY_USER);
  const {
    data: guideData,
    loading: guideDataLoading,
    error: guideDataError,
  } = useQuery<GuideDetailsForIdentity, GuideDetailsForIdentityVariables>(
    GUIDE_DETAILS_FOR_IDENTITY,
    {
      // only call if we're on a guide page + there's no user info
      skip: !isGuidePage || userDataLoading || !!data?.currentUserPrisma,
      variables: {
        customerSlug,
        guideShortId,
      },
    }
  );

  useEffect(() => {
    const userData = data?.currentUserPrisma;
    if (userData) {
      client.identifyUser(
        {
          ...userData,
          roles: userData.currentUserMembership?.roles ?? [],
          currentOrganization: userData.currentOrganization || undefined,
        },
        undefined,
        (err) => {
          if (err) {
            captureSentryError(err, "analytics identifyUser user");
          }
          setFlagsLoading(false);
        }
      );
    } else if (guideData?.guideFindUniqueByShortId?.candidate) {
      const { candidate } = guideData.guideFindUniqueByShortId;

      const candidateAsUser = {
        id: candidate.id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        primaryEmail: candidate.email,
        currentOrganization: candidate.organization,
        roles: [],
      };

      client.identifyUser(candidateAsUser, undefined, (err) => {
        if (err) {
          captureSentryError(err, "analytics identifyUser candidate");
        }
        setFlagsLoading(false);
      });
    } else if (
      // If either request error'd or both have finished (and the above conditions aren't met)
      userDataError ||
      guideDataError ||
      (!userDataLoading && !guideDataLoading)
    ) {
      setFlagsLoading(false);
    }
  }, [
    client,
    data,
    guideData,
    guideDataError,
    guideDataLoading,
    userDataError,
    userDataLoading,
  ]);

  return (
    <FlagsLoadingContext.Provider value={{ loading: flagsLoading }}>
      {children}
    </FlagsLoadingContext.Provider>
  );
}

export default IdentifyUserWrapper;
