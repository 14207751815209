import { createContext, useContext, useMemo } from "react";

export default function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const InV2FrameContext = createContext<{ sourceFrameIsGuideV2: boolean }>({
  sourceFrameIsGuideV2: false,
});

export function InV2FrameProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const sourceFrameIsGuideV2 = useMemo(() => {
    const framed = inIframe();
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      return framed && queryParams.get("source") === "guide-v2";
    }
    return false;
  }, []); // Empty dependency array ensures this runs once per application load

  return (
    <InV2FrameContext.Provider value={{ sourceFrameIsGuideV2 }}>
      {children}
    </InV2FrameContext.Provider>
  );
}

export const useInV2Frame = () =>
  useContext(InV2FrameContext).sourceFrameIsGuideV2;
