import { RoleEnum } from "enums/role-enum";
import _ from "lodash";

// data
// ----

export const enum PermissionEnum {
  // org management
  ORGANIZATION_INTEGRATIONS_WRITE,
  ORGANIZATION_BRAND_WRITE,
  ORGANIZATION_WRITE,

  // user management
  USER_READ,
  USER_INVITE,
  USER_ROLES_WRITE,
  USER_PROFILE_WRITE,
  USER_DEACTIVATE,
  USER_ACTIVATE,

  // journeys
  JOURNEY_WRITE,

  // templates
  TEMPLATE_READ,
  TEMPLATE_WRITE,

  // analytics
  ANALYTICS_READ,

  // guides
  GUIDE_READ,
  GUIDE_WRITE,

  // Pitch Pages
  PITCH_PAGE_TEMPLATE_READ,
  PITCH_PAGE_TEMPLATE_WRITE,
}

export enum StaffRoleEnum {
  STAFF = "staff",
  SUPERUSER = "superuser",
}

export const OrderedRoles = [
  RoleEnum.Admin,
  RoleEnum.Manager,
  RoleEnum.Member,
  RoleEnum.Interviewer,
  RoleEnum.Candidate,
] as RoleEnum[];

// aliases for legibility
const Role = RoleEnum;
// note: this is not an actual import, but a namespace alias, so the eslint error is inaccurate
// see: https://www.typescriptlang.org/docs/handbook/namespaces.html#aliases
// eslint-disable-next-line import/first
import Perm = PermissionEnum;

export const PERMISSIONS_BY_ROLE: Record<RoleEnum, PermissionEnum[]> = {
  [Role.Admin]: [
    Perm.ORGANIZATION_INTEGRATIONS_WRITE,
    Perm.ORGANIZATION_BRAND_WRITE,
    Perm.ORGANIZATION_WRITE,

    Perm.USER_READ,
    Perm.USER_INVITE,
    Perm.USER_ROLES_WRITE,
    Perm.USER_PROFILE_WRITE,
    Perm.USER_DEACTIVATE,
    Perm.USER_ACTIVATE,

    Perm.JOURNEY_WRITE,

    Perm.TEMPLATE_READ,
    Perm.TEMPLATE_WRITE,

    Perm.ANALYTICS_READ,

    Perm.GUIDE_READ,
    Perm.GUIDE_WRITE,

    Perm.PITCH_PAGE_TEMPLATE_READ,
    Perm.PITCH_PAGE_TEMPLATE_WRITE,
  ],
  [Role.Manager]: [
    Perm.ORGANIZATION_WRITE,

    Perm.USER_READ,
    Perm.USER_INVITE,
    Perm.USER_ROLES_WRITE,
    Perm.USER_PROFILE_WRITE,

    Perm.JOURNEY_WRITE,

    Perm.TEMPLATE_READ,
    Perm.TEMPLATE_WRITE,

    Perm.ANALYTICS_READ,

    Perm.GUIDE_READ,
    Perm.GUIDE_WRITE,

    Perm.PITCH_PAGE_TEMPLATE_READ,
    Perm.PITCH_PAGE_TEMPLATE_WRITE,
  ],
  [Role.Member]: [
    Perm.USER_READ,
    Perm.USER_PROFILE_WRITE,
    Perm.ANALYTICS_READ,
    Perm.GUIDE_READ,
    Perm.GUIDE_WRITE,

    Perm.JOURNEY_WRITE,

    Perm.TEMPLATE_READ,

    Perm.PITCH_PAGE_TEMPLATE_READ,
    Perm.PITCH_PAGE_TEMPLATE_WRITE,
  ],
  [Role.Interviewer]: [],
  [Role.Candidate]: [],
};

// utils
// -----

export function checkRolesPermissions(
  roles: RoleEnum[],
  permissions: PermissionEnum[]
): boolean {
  return permissions.every((permission) =>
    roles.some((role) => PERMISSIONS_BY_ROLE[role].includes(permission))
  );
}

export const getHighestRole = (roles: RoleEnum[]) =>
  _(roles)
    .intersection(OrderedRoles)
    .sortBy((role: string) => _.indexOf(OrderedRoles, role))
    .first();
