import { loadIntercom } from "next-intercom";
import { useEffect } from "react";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}

export default () => {
  useEffect(() => {
    const updateIntercom = async () => {
      await loadIntercom({
        appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        // userHash and userId set in AnalyticsWrapper
      });
      window.Intercom("update", { hide_default_launcher: true });
    };
    updateIntercom();
  }, []);

  return {
    callIntercom: (...args: unknown[]) => {
      if (window.Intercom) {
        window.Intercom(...args);
      } else {
        console.warn("No Intercom detected on window", args);
        console.debug("Intercom called with", ...args);
      }
    },
  };
};
