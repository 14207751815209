import _ from "lodash";
import { match, MatchFunction } from "path-to-regexp";
import { formatEntity } from "shared/constants/entities";
import urljoin from "url-join";

type Route = {
  matcher: MatchFunction;
  /** Name of page -- used for analytics */
  name: string;
  /** Title of page -- used for display */
  title?: string;
  disableTrackPage?: boolean;
  customerSuccess?: boolean;
};

const RouteMatching: Route[] = [
  {
    matcher: match("/_error"),
    name: "Server Error",
  },
  {
    matcher: match("/close"),
    name: "Close",
  },
  {
    matcher: match("/invalid-permissions"),
    name: "Invalid Permissions",
  },
  {
    matcher: match("/admin"),
    name: "Admin",
  },
  {
    matcher: match("/new-user"),
    name: "New User",
  },
  {
    matcher: match("/new-user/tutorial"),
    name: "New User Tutorial",
  },
  {
    matcher: match("/new-user/image"),
    name: "New User Profile Image",
  },
  {
    matcher: match("/live-chat"),
    name: "Live Chat",
  },
  {
    matcher: match("/candidate"),
    name: "Candidate Redirect",
  },
  {
    matcher: match("/cookies-required"),
    name: "Cookies Required",
  },
  {
    matcher: match("/merge-candidate/greenhouse/:applicationId"),
    name: "Merge Greenhouse Candidate",
  },
  {
    matcher: match("/merge-candidate/lever/:applicationId"),
    name: "Merge Lever Candidate",
  },
  {
    matcher: match(
      "/greenhouse-candidate/:candidateId/application/:applicationId"
    ),
    name: "Guide Progress",
  },
  {
    matcher: match("/greenhouse-application/:applicationId"),
    name: "Guide Progress",
  },
  {
    matcher: match("/extension/modals"),
    name: "Guide Modals",
    disableTrackPage: true,
  },
  {
    matcher: match("/extension/guide/:guideId"),
    name: "Guide Progress",
  },
  {
    matcher: match("/extension/guide/:guideId/send"),
    name: "Send Guide",
  },
  {
    matcher: match("/extension/guide/:guideId/move"),
    name: "Move to Stage",
  },
  {
    matcher: match("/extension/guide/:guideId/interviews"),
    name: "Extension Interviews",
  },
  {
    matcher: match("/extension/guide/:guideId/:stageId"),
    name: "Stage Details",
  },
  {
    matcher: match(
      "/extension/guide/:guideId/:stageId/:eventTemplateInstallationId"
    ),
    name: "Interview Details",
  },
  {
    matcher: match(
      "/extension/guide/:guideId/:stageId/:eventTemplateInstallationId/:eventInterviewerId"
    ),
    name: "Participant Details",
  },
  {
    matcher: match("/upsell(.*)"),
    name: "Upsell",
  },
  {
    matcher: match("/login(.*)"),
    name: "Login",
  },
  {
    matcher: match("/onboarding"),
    name: "Pitch Page Onboarding",
  },
  {
    matcher: match("/journeys/:journeyId"),
    name: "Role Journey",
  },
  {
    matcher: match("/journeys"),
    name: "Journeys",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/job-details"),
    name: "Job Guide Settings Job Details",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/stages"),
    name: "Job Guide Settings Hiring Plan",
    title: formatEntity("interview plan", { capitalize: true }),
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/default-interviews"),
    name: "Job Guide Settings Interviews",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/options"),
    name: "Job Guide Settings Options",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/guides"),
    name: "Job Guides",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/candidates"),
    name: "Job Candidates",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/interviews"),
    name: "Job Interviews",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId/setup"),
    name: "Job Setup",
  },
  {
    matcher: match("/jobs/:jobRoleGuideTemplateId"),
    name: "Job",
  },
  {
    matcher: match("/jobs"),
    name: "Jobs",
  },
  {
    matcher: match("/templates/content/:stageTemplateId"),
    name: "Content Template",
  },
  {
    matcher: match("/p/:pitchPageId"),
    name: "Pitch Page View",
  },
  {
    matcher: match("/pitch-pages/:pitchPageTemplateId/design"),
    name: "Pitch Page Template Design",
  },
  {
    matcher: match("/pitch-pages/:pitchPageTemplateId/share-links"),
    name: "Pitch Page Template Share Links",
  },
  {
    matcher: match("/pitch-pages"),
    name: "Pitch Page Templates",
  },
  {
    matcher: match("/s/:pageSlug/:editHash/:method"),
    name: "Scheduler Page Reschedule or Cancel",
  },
  {
    matcher: match("/s/:pageSlug/:editHash"),
    name: "Scheduler Page Manage",
  },
  {
    matcher: match("/s/:pageSlug"),
    name: "Scheduler Page",
  },
  {
    matcher: match("/scheduler"),
    name: "Scheduler",
  },
  {
    matcher: match("/scheduler/availabilities"),
    name: "Scheduler Availabilities",
  },
  {
    matcher: match("/scheduler/availabilities"),
    name: "Scheduler Availabilities",
  },
  {
    matcher: match("/templates"),
    name: "Templates",
    customerSuccess: true,
  },
  {
    matcher: match("/templates/messages"),
    name: "Message Templates",
    title: `${formatEntity("message", { capitalize: true })} Templates`,
  },
  {
    matcher: match("/templates/emails"),
    name: "Email Templates",
  },
  {
    matcher: match("/templates/content"),
    name: "Content Templates",
  },
  {
    matcher: match("/templates/guides"),
    name: "Guide Templates",
    title: formatEntity("job template", { capitalize: true, plural: true }),
  },
  {
    matcher: match("/templates/guides/:guideTemplateId/stages"),
    name: "Guide Template Hiring Plan",
    title: `${formatEntity("job template", { capitalize: true })} Hiring Plan`,
  },
  {
    matcher: match("/templates/guides/:guideTemplateId/options"),
    name: "Guide Template Options",
    title: `${formatEntity("job template", { capitalize: true })} Options`,
  },
  {
    matcher: match("/templates/interviews"),
    name: "Interview Templates",
    customerSuccess: true,
  },
  {
    matcher: match("/settings"),
    name: "Settings",
    customerSuccess: true,
  },
  {
    matcher: match("/settings/users"),
    name: "Users",
  },
  {
    matcher: match("/settings/company"),
    name: "Company Profile",
    customerSuccess: true,
  },
  {
    matcher: match("/settings/integrations"),
    name: "Company Integrations",
  },
  {
    matcher: match("/settings/configuration"),
    name: "Company Configuration",
  },
  {
    matcher: match("/settings/interview-templates"),
    name: "Interview Templates",
  },
  {
    matcher: match("/g/:customerSlug/:guideShortId/:candidateName"),
    name: "Candidate Guide",
  },
  {
    matcher: match("/account"),
    name: "Account",
  },
  {
    matcher: match("/account/profile"),
    name: "Account Profile",
    customerSuccess: true,
  },
  {
    matcher: match("/account/integrations"),
    name: "Account Integrations",
    customerSuccess: true,
  },
  {
    matcher: match("/account/settings"),
    name: "Account Settings",
    customerSuccess: true,
  },
  {
    matcher: match("/feedback"),
    name: "Feedback",
  },
  {
    matcher: match("/surveys"),
    name: "Feedback",
    title: formatEntity("survey", { capitalize: true, plural: true }),
    customerSuccess: true,
  },
  {
    matcher: match("/insights"),
    name: "Insights",
  },
  {
    matcher: match("/insights/active-coverage"),
    name: "Insights Active Coverage",
  },
  {
    matcher: match("/preview/job/:jobRoleGuideTemplateId"),
    name: "Preview Job Guide Settings",
  },
  {
    matcher: match("/preview/guide/:guideId"),
    name: "Preview Guide",
  },
  {
    matcher: match("/preview/guide-template/:guideId"),
    name: "Preview Guide Template",
  },
  {
    matcher: match("/"),
    name: "Home",
  },
  {
    matcher: match("/guide/:customerSlug/:guideShortId"),
    name: "Home Page - Candidate Guide",
    title: "Home",
    customerSuccess: true,
  },
  {
    matcher: match("/guide/:customerSlug/:guideShortId/messages"),
    name: "Conversations",
    title: formatEntity("message", {
      capitalize: true,
      plural: true,
    }),
    customerSuccess: true,
  },
  {
    matcher: match("/guide/:customerSlug/:guideShortId/message/:messageId"),
    name: "Conversation",
    title: formatEntity("message", {
      capitalize: true,
    }),
    customerSuccess: true,
  },
  {
    matcher: match("/guide/:customerSlug/:guideShortId/interviews"),
    name: "Interviews Page - Candidate Guide",
    title: "Interviews",
    customerSuccess: true,
  },
  {
    matcher: match("/guide/:customerSlug/:guideShortId/interviews/past"),
    name: "Past Interviews Page - Candidate Guide",
    title: "Past Interviews",
  },
  {
    matcher: match("/extension"),
    name: "Guide Extension",
    title: "Guide Extension Sidepanel",
    disableTrackPage: true,
  },
  {
    matcher: match("/inbox"),
    name: "Inbox",
    title: "Inbox",
    customerSuccess: true,
  },
  {
    matcher: match("/booking-confirmed"),
    name: "Booking Confirmed",
    title: "Booking Confirmed",
  },
  {
    matcher: match("/co2"),
    name: "Carbon Dioxide",
    title: "Carbon Dioxide",
    disableTrackPage: true,
  },
  {
    matcher: match("/signup"),
    name: "Sign Up",
    title: "Sign Up",
  },
  {
    matcher: match("/:customerSlug/:guideShortId/:candidateName"),
    name: "Candidate Guide",
    customerSuccess: true,
  },
];

const routeForPathname = (
  pathnameWithQueryParams: string
): Route | undefined => {
  const url = new URL(pathnameWithQueryParams, "https://app.guide.co");
  return _.find(RouteMatching, ({ matcher }) => !!matcher(url.pathname));
};

export const appendRelativePath = (
  routerPathWithQueryParams: string,
  relativePath: string,
  qs: string | undefined = ""
): string => {
  const url = new URL(
    routerPathWithQueryParams,
    process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? process.env.STORYBOOK_APP_ROOT
  );
  return urljoin(url.pathname, relativePath, qs);
};

export default routeForPathname;
