import { Analytics } from "types/Analytics";

declare global {
  interface Window {
    analytics?: Partial<Analytics>;
  }
}

export const analytics: Analytics = {
  // @ts-expect-error - not worth working around the overload, this is fine
  identify: (...args) => window.analytics?.identify?.(...args),
  track: (...args) => window.analytics?.track?.(...args),
  page: (...args) => window.analytics?.page?.(...args),
  group: (...args) => window.analytics?.group?.(...args),
};

/** @deprecated Use "analytics" directly instead. */
function useAnalytics(): Analytics {
  return analytics;
}

export default useAnalytics;
