import { ReactNode, useEffect, useLayoutEffect, useState } from "react";

const config = { attributes: true, childList: true, subtree: true };

// Note: redefining this code as I intend for this to be moved out into it's own package
function sendMessageToParent(message: object) {
  if (window.parent?.parent) {
    window.parent.parent.postMessage(message, "*");
  }
}

function serializeElement(el: Element | null) {
  if (!el) {
    return null;
  }

  const rect = el.getBoundingClientRect();
  const style = window.getComputedStyle(el);

  return {
    rect: {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    },
    // TODO: we should maybe send it all? Sending raw object fails (can't be serialized) and sending every property
    // feels like a big message to send
    style: {
      borderTopLeftRadius: style.borderTopLeftRadius,
      borderTopRightRadius: style.borderTopRightRadius,
      borderBottomLeftRadius: style.borderBottomLeftRadius,
      borderBottomRightRadius: style.borderBottomRightRadius,
    },
  };
}

export function TourContext({ children }: { children?: ReactNode }) {
  const [watching, setWatching] = useState<Record<string, string | string[]>>(
    {}
  );
  const [toClick, setToClick] = useState<string[]>([]);

  useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (typeof ev.data === "object") {
        if (ev.data.command === "tour-query-selector") {
          if (ev.data.selector) {
            setWatching((w) => ({ ...w, [ev.data.id]: ev.data.selector }));
          } else {
            setWatching((w) => {
              const { [ev.data.id]: toRemove, ...rest } = w;
              return { ...rest };
            });
          }
        } else if (ev.data.command === "tour-click") {
          setToClick((c) => [...c, ev.data.selector]);
        }
      }
    };
    window.addEventListener("message", listener);

    sendMessageToParent({
      command: "tour-remote-ready",
    });
    return () => window.removeEventListener("message", listener);
  }, []);

  useLayoutEffect(() => {
    Object.keys(watching).forEach((id) => {
      // TODO: track what we sent so that we can only send back if we actually need to
      const selectors = watching[id];

      (Array.isArray(selectors) ? selectors : [selectors]).forEach(
        (selector) => {
          const element = serializeElement(document.querySelector(selector));
          sendMessageToParent({
            command: "tour-query-selector-result",
            selector,
            element,
            id,
          });
        }
      );
    });

    toClick.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element && element instanceof HTMLElement) {
        setToClick((c) => c.filter((id) => id !== selector));
        element.click();
      }
    });

    // TODO: this is repeated from above
    const observer = new MutationObserver(() => {
      Object.keys(watching).forEach((id) => {
        // TODO: track what we sent so that we can only send back if we actually need to
        const selectors = watching[id];

        (Array.isArray(selectors) ? selectors : [selectors]).forEach(
          (selector) => {
            const element = serializeElement(document.querySelector(selector));
            sendMessageToParent({
              command: "tour-query-selector-result",
              selector,
              element,
              id,
            });
          }
        );
      });

      toClick.forEach((selector) => {
        const element = document.querySelector(selector);
        if (element && element instanceof HTMLElement) {
          setToClick((c) => c.filter((id) => id !== selector));
          element.click();
        }
      });
    });
    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  }, [watching, toClick, setToClick]);

  return <>{children}</>;
}
