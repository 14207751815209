import { onError } from "@apollo/client/link/error";
import { ErrorCodes } from "@resource/common";
import { get } from "lodash";

export default (handleRedirect: (errorCode?: string) => void) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.find(
          (error) => get(error, "extensions.code") === "UNAUTHENTICATED"
        )
      ) {
        console.error("User is unauthenticated.", window.location.pathname);
        if (handleRedirect) {
          handleRedirect();
        }
      }
      const customError = graphQLErrors.find((error) =>
        [ErrorCodes.INACTIVE_USER, ErrorCodes.TRIAL_ENDED].includes(
          get(error, "extensions.code")
        )
      );
      if (customError) {
        if (handleRedirect) {
          handleRedirect(customError!.extensions!.code);
        }
      }
    }
  });
