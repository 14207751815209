/* eslint-disable no-restricted-syntax */
import * as Sentry from "@sentry/nextjs";

export function captureSentryError(error: Error, title?: string) {
  if (title) {
    Sentry.withScope((scope) => {
      scope.setFingerprint([title]);
      Sentry.captureException(error);
    });
  } else {
    Sentry.captureException(error);
  }
}
