/* eslint-disable react/destructuring-assignment */
import { IdProvider as RadixProvider } from "@radix-ui/react-id";
import { SSRProvider as ReactAriaProvider } from "@react-aria/ssr";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { ReactElement, ReactNode } from "react";

import { AtlasProviderProps } from "./types";

// config
// ------

const NOTISTACK_OPTIONS: Omit<SnackbarProviderProps, "children"> = {
  maxSnack: 2,
  anchorOrigin: {
    horizontal: "right",
    vertical: "bottom",
  },
};

// toast provider
// --------------

function ToastProvider(p: { children?: ReactNode }) {
  return (
    <SnackbarProvider {...NOTISTACK_OPTIONS}>{p.children}</SnackbarProvider>
  );
}

// design system provider
// ----------------------

function Provider(p: AtlasProviderProps): ReactElement {
  return (
    <RadixProvider>
      <ReactAriaProvider>
        <ToastProvider>{p.children}</ToastProvider>
      </ReactAriaProvider>
    </RadixProvider>
  );
}

export default Provider;
