import { createContext, ReactNode, useContext, useMemo } from "react";

type GuideIdContextValue = {
  guideId?: string;
};

const GuideIdContext = createContext<GuideIdContextValue | undefined>(
  undefined
);

export function GuideIdProvider({
  guideId,
  children,
}: {
  guideId?: string;
  children?: ReactNode;
}) {
  const value = useMemo(() => ({ guideId }), [guideId]);

  return (
    <GuideIdContext.Provider value={value}>{children}</GuideIdContext.Provider>
  );
}

export function useGuideIdContext() {
  return useContext(GuideIdContext);
}
