// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as FullStory from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const sentryEnvironment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.002,
  maxValueLength: 500,
  enabled: sentryEnvironment !== "development",
  environment: sentryEnvironment,
  integrations: [new SentryFullStory("idk-labs", { client: FullStory })],
  ignoreErrors: [
    /^(Error: )?Failed to fetch$/,
    /^(Error: )?Load failed$/,
    /^(Error: )?NetworkError when attempting to fetch resource/,
    /^(Error: )?Network Error$/,
  ],
});
