import { gql } from "@apollo/client";

export const ANALYTICS_METADATA_ORGANIZATION = gql`
  fragment AnalyticsMetadataOrganization on Organization {
    companyLogoUrl
    id
    name
    createdAt
    zeusStatus
    planPricingModel
    accountStatus
    employeeCountEnum
    talentTeamCountEnum
    contractStartDate
    customer {
      id
      trialDuration
      trialStart
      name
      plan {
        id
        name
      }
    }
  }
`;
